import React from "react";
import i18n from "i18n-js";
import { Container,Col,Row } from "react-bootstrap";
import asha from '../../../static/assets/images/approach/asha.png'
const Asha = () => {
  return (
    <section className="mhh-bg-image approach-asha">
      <Container>
        <Row noGutters className="justify-content-center">

          <Col xs={12} sm={12} md={4} lg={4} xl={4} id='approach-asha-sec'>
            <div className="text-center">
              <img src={asha} width="100%" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div>
              <h1>{i18n.t("asha-heading")}</h1>
              <div>
                <p>{i18n.t("asha-text1")}</p>
                <p dangerouslySetInnerHTML={{ __html:i18n.t("asha-text2")}}></p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Asha;
